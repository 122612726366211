@import '~@vg-constellation/styles/c11n';
@import '~@vg-constellation/styles/styles.css';
@import '~@vg-constellation/styles/grid';
@import '~@vg-constellation/styles/typography';
@import '~@vg-constellation/styles/breakpoints';

@import './styles/table';

$static-url: 'https://digital-advisor.vanguard.com/static';
@import url('#{$static-url}/fonts/fonts.css');

// ss-lib-secondary-nav z-index for mobile view
.c11n-nav-overflow__menu {
    z-index: 1610 !important;
}

.error-banner.c11n-banner.c11n-banner--error .c11n-banner__content-header {
    font-weight: 400 !important;
}

// Apply z-index to session timeout modal popup
#idle-timer-modal-scrim {
    z-index: 1612 !important;
}

@media screen and (min-width: 302px) and (max-width: 340px) {
    .tetriary-navigation-dropdown__content {
        max-width: 13rem !important;

        button {
            display: inherit !important;
        }
    }
}

.hd-modal-entrypoint > dialog.c11n-modal-dialog {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}
